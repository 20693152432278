import * as React from 'react';

import Index from './index';

// const signupPage = () => <Index path="/signup" />;
const signupPage = () => (
  // TODO REMOVE AFTER MIGRATION
  <div
    style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <p style={{ maxWidth: '580px', textAlign: 'center' }}>
      We are in the process of migrating our website to improve your experience. Temporary
      registrations are disabled at this time. Please check back in a few days. Thank you for your
      patience!
    </p>
  </div>
);

export default signupPage;
